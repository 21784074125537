import {createIcon} from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={48}
      viewBox="0 0 1479 544"
      {...props}>
      <path
        id="squerl2"
        fill="#2151a1"
        d="M 474.56,69.61
                   C 426.23,21.47 356.75,16.09 271.84,16.09
                     186.83,16.09 117.24,21.54 68.94,69.80
                     20.64,118.05 15.74,187.52 15.74,272.44
                     15.74,357.37 20.64,426.86 68.96,475.12
                     117.26,523.40 186.84,528.28 271.84,528.28
                     356.85,528.28 426.42,523.40 474.72,475.12
                     523.03,426.85 527.95,357.37 527.95,272.44
                     527.95,187.41 522.98,117.86 474.56,69.61
                     474.56,69.61 474.56,69.61 474.56,69.61 Z"
      />
      <path
        id="small-circle2"
        fill="#eb1933"
        d="M 420.85,272.16
                   C 420.85,355.02 353.68,422.19 270.82,422.19
                     187.96,422.19 120.78,355.02 120.78,272.16
                     120.78,189.30 187.96,122.13 270.82,122.13
                     353.68,122.13 420.85,189.30 420.85,272.16 Z"
      />
      <path
        id="crosshair3"
        fill="#ffffff"
        d="M 259.14,147.83
                  C 259.14,147.83 282.55,147.83 282.55,147.83
                    282.55,147.83 282.54,121.93 282.54,121.93
                    356.08,127.49 414.81,186.05 420.63,259.51
                    420.63,259.51 394.84,259.50 394.84,259.50
                    394.84,259.50 394.84,282.17 394.84,282.17
                    394.84,282.17 420.73,282.17 420.73,282.17
                    415.55,356.08 356.84,415.22 283.12,421.08
                    283.12,421.08 283.12,395.33 283.12,395.33
                    283.12,395.33 259.68,395.33 259.68,395.33
                    259.68,395.33 259.68,421.13 259.68,421.13
                    185.91,415.59 127.01,356.71 121.46,282.95
                    121.46,282.95 147.85,282.95 147.85,282.95
                    147.85,282.95 147.85,260.53 147.85,260.53
                    147.85,260.53 121.43,260.53 121.43,260.53
                    126.78,186.75 185.48,127.76 259.14,121.96
                    259.14,121.96 259.14,147.83 259.14,147.83 Z
                  M 282.55,65.04
                  C 282.55,65.04 259.14,65.04 259.14,65.04
                    259.14,65.04 259.14,91.87 259.14,91.87
                    168.90,97.77 96.79,170.16 91.35,260.53
                    91.35,260.53 65.02,260.53 65.02,260.53
                    65.02,260.53 65.02,282.95 65.02,282.95
                    65.02,282.95 91.38,282.95 91.38,282.95
                    97.04,373.29 169.33,445.57 259.68,451.20
                    259.68,451.20 259.68,478.17 259.68,478.17
                    259.68,478.17 283.12,478.17 283.12,478.17
                    283.12,478.17 283.11,451.16 283.11,451.16
                    373.42,445.20 445.52,372.65 450.80,282.16
                    450.80,282.16 477.90,282.17 477.90,282.17
                    477.90,282.17 477.90,259.50 477.90,259.50
                    477.90,259.50 450.71,259.53 450.71,259.53
                    444.79,169.49 372.67,97.52 282.55,91.85
                    282.55,91.85 282.55,65.04 282.55,65.04 Z"
      />
      <path
        id="A2"
        fill="#ffffff"
        d="M 180.27,365.69
                   C 178.16,365.69 174.00,366.15 173.10,363.70
                     172.39,361.76 176.40,351.53 177.14,349.28
                     177.14,349.28 186.39,320.78 186.39,320.78
                     186.39,320.78 213.81,235.27 213.81,235.27
                     213.81,235.27 220.59,214.55 220.59,214.55
                     220.59,214.55 229.85,186.04 229.85,186.04
                     230.73,183.04 233.12,173.86 235.14,172.00
                     237.08,170.19 240.68,170.50 243.17,170.50
                     243.17,170.50 299.34,170.50 299.34,170.50
                     301.98,170.50 305.46,170.22 307.61,172.00
                     309.93,173.90 310.78,178.86 311.81,181.72
                     311.81,181.72 319.95,206.77 319.95,206.77
                     319.95,206.77 324.82,221.46 324.82,221.46
                     324.82,221.46 335.91,256.00 335.91,256.00
                     335.91,256.00 357.52,323.37 357.52,323.37
                     357.52,323.37 366.73,351.87 366.73,351.87
                     367.48,354.13 370.29,361.76 369.58,363.70
                     368.69,366.15 364.52,365.69 362.41,365.69
                     362.41,365.69 319.21,365.69 319.21,365.69
                     316.87,365.68 313.72,365.97 311.87,364.31
                     310.16,362.77 308.47,356.08 307.74,353.60
                     307.74,353.60 300.20,325.10 300.20,325.10
                     300.20,325.10 259.59,325.10 259.59,325.10
                     257.54,325.10 253.17,325.42 251.84,323.59
                     250.60,321.91 252.57,316.00 253.04,313.87
                     253.04,313.87 256.77,299.19 256.77,299.19
                     257.21,297.51 257.84,294.21 259.23,293.16
                     260.56,292.15 263.16,292.29 264.77,292.28
                     264.77,292.28 290.70,292.28 290.70,292.28
                     290.70,292.28 270.84,216.17 270.84,216.17
                     270.84,216.17 238.42,330.28 238.42,330.28
                     238.42,330.28 231.82,353.60 231.82,353.60
                     231.16,355.87 229.32,362.88 227.86,364.31
                     226.14,366.00 222.95,365.68 220.71,365.69
                     220.71,365.69 180.27,365.69 180.27,365.69 Z"
      />
      <path
        id="font2"
        fill="currentColor"
        d="M 797.06,362.42
                   C 797.06,362.42 702.63,362.42 702.63,362.42
                     702.63,362.42 689.63,406.67 689.63,406.67
                     689.63,406.67 604.89,406.67 604.89,406.67
                     604.89,406.67 705.74,138.54 705.74,138.54
                     705.74,138.54 796.15,138.54 796.15,138.54
                     796.15,138.54 896.99,406.67 896.99,406.67
                     896.99,406.67 810.24,406.67 810.24,406.67
                     810.24,406.67 797.06,362.42 797.06,362.42 Z
                   M 779.68,304.46
                   C 779.68,304.46 750.03,208.05 750.03,208.05
                     750.03,208.05 720.56,304.46 720.56,304.46
                     720.56,304.46 779.68,304.46 779.68,304.46 Z
                   M 1051.71,309.52
                   C 1051.71,309.52 1051.71,253.72 1051.71,253.72
                     1051.71,253.72 1179.82,253.72 1179.82,253.72
                     1179.82,253.72 1179.82,368.10 1179.82,368.10
                     1155.30,384.79 1133.61,396.16 1114.76,402.19
                     1095.91,408.22 1073.55,411.24 1047.68,411.24
                     1015.84,411.24 989.88,405.81 969.81,394.96
                     949.74,384.11 934.18,367.95 923.14,346.49
                     912.10,325.03 906.58,300.40 906.58,272.60
                     906.58,243.34 912.62,217.89 924.70,196.24
                     936.78,174.60 954.47,158.17 977.77,146.95
                     995.95,138.29 1020.41,133.97 1051.16,133.97
                     1080.81,133.97 1102.99,136.65 1117.69,142.02
                     1132.39,147.38 1144.59,155.71 1154.29,166.99
                     1163.99,178.27 1171.28,192.57 1176.16,209.89
                     1176.16,209.89 1096.18,224.15 1096.18,224.15
                     1092.89,214.03 1087.31,206.29 1079.44,200.93
                     1071.57,195.57 1061.53,192.88 1049.33,192.88
                     1031.15,192.88 1016.66,199.19 1005.87,211.81
                     995.07,224.43 989.67,244.40 989.67,271.71
                     989.67,300.74 995.13,321.47 1006.05,333.90
                     1016.97,346.34 1032.19,352.56 1051.71,352.56
                     1060.98,352.56 1069.83,351.22 1078.25,348.53
                     1086.67,345.84 1096.30,341.27 1107.16,334.79
                     1107.16,334.79 1107.16,309.52 1107.16,309.52
                     1107.16,309.52 1051.71,309.52 1051.71,309.52 Z
                   M 1210.24,138.54
                   C 1210.24,138.54 1462.26,138.54 1462.26,138.54
                     1462.26,138.54 1462.26,204.67 1462.26,204.67
                     1462.26,204.67 1377.70,204.67 1377.70,204.67
                     1377.70,204.67 1377.70,406.67 1377.70,406.67
                     1377.70,406.67 1294.80,406.67 1294.80,406.67
                     1294.80,406.67 1294.80,204.67 1294.80,204.67
                     1294.80,204.67 1210.24,204.67 1210.24,204.67
                     1210.24,204.67 1210.24,138.54 1210.24,138.54 Z"
      />
    </svg>
  )
}

export const Spy = (props: any) => {
  return (
    <svg
      width={24}
      viewBox="0 0 413 413"
      xmlns="http://www.w3.org/2000/svg"
      {...props.style}>
      <path
        id="small-circle4"
        fill="#2151a1"
        d="M 355.48,207.03
            C 355.48,289.51 288.62,356.37 206.13,356.37
              123.65,356.37 56.78,289.51 56.78,207.03
              56.78,124.55 123.65,57.68 206.13,57.68
              288.62,57.68 355.48,124.55 355.48,207.03 Z"
      />
      <text
        id="#text"
        x="50%"
        y="55%"
        fill="white"
        dominant-baseline="middle"
        text-anchor="middle"
        fontSize={'250'}>
        {props.number}
      </text>
      <path
        id="crosshair4"
        fill="currentColor"
        d="M 194.14,82.83
            C 194.14,82.83 217.55,82.83 217.55,82.83
              217.55,82.83 217.54,56.93 217.54,56.93
              291.08,62.49 349.81,121.05 355.63,194.51
              355.63,194.51 329.84,194.50 329.84,194.50
              329.84,194.50 329.84,217.17 329.84,217.17
              329.84,217.17 355.73,217.17 355.73,217.17
              350.55,291.08 291.84,350.22 218.12,356.08
              218.12,356.08 218.12,330.33 218.12,330.33
              218.12,330.33 194.68,330.33 194.68,330.33
              194.68,330.33 194.68,356.13 194.68,356.13
              120.91,350.59 62.01,291.71 56.46,217.95
              56.46,217.95 82.85,217.95 82.85,217.95
              82.85,217.95 82.85,195.53 82.85,195.53
              82.85,195.53 56.43,195.53 56.43,195.53
              61.78,121.75 120.48,62.76 194.14,56.96
              194.14,56.96 194.14,82.83 194.14,82.83 Z
            M 217.55,0.04
            C 217.55,0.04 194.14,0.04 194.14,0.04
              194.14,0.04 194.14,26.87 194.14,26.87
              103.90,32.77 31.79,105.16 26.35,195.53
              26.35,195.53 0.02,195.53 0.02,195.53
              0.02,195.53 0.02,217.95 0.02,217.95
              0.02,217.95 26.38,217.95 26.38,217.95
              32.04,308.29 104.33,380.57 194.68,386.20
              194.68,386.20 194.68,413.17 194.68,413.17
              194.68,413.17 218.12,413.17 218.12,413.17
              218.12,413.17 218.11,386.16 218.11,386.16
              308.42,380.20 380.52,307.65 385.80,217.16
              385.80,217.16 412.90,217.17 412.90,217.17
              412.90,217.17 412.90,194.50 412.90,194.50
              412.90,194.50 385.71,194.53 385.71,194.53
              379.79,104.49 307.67,32.52 217.55,26.85
              217.55,26.85 217.55,0.04 217.55,0.04 Z"
      />
    </svg>
  )
}

export const Bullet = createIcon({
  displayName: 'BulletIcon',
  viewBox: '0 0 100 23.013',
  d: `M 75.30,3.31
        C 75.30,3.31 65.90,3.31 65.90,3.31
            65.90,3.31 59.70,0.11 59.70,0.11
            59.64,0.10 59.58,0.08 59.52,0.07
            59.39,0.04 59.28,0.02 59.19,0.02
            59.16,0.01 59.13,0.01 59.10,0.01
            59.10,0.01 59.10,0.01 59.10,0.01
            59.10,0.01 11.50,0.01 11.50,0.01
            11.50,0.01 10.90,0.11 10.90,0.11
            10.90,0.11 6.60,1.61 6.60,1.61
            6.60,1.61 2.20,0.11 2.20,0.11
            1.67,-0.09 1.17,-0.02 0.70,0.31
            0.32,0.57 0.06,0.99 0.01,1.46
            0.00,1.54 -0.00,1.63 -0.00,1.71
            -0.00,1.71 -0.00,1.71 -0.00,1.71
            -0.00,1.71 0.00,11.51 0.00,11.51
            0.00,11.51 0.00,21.30 0.00,21.30
            -0.00,21.31 -0.00,21.32 -0.00,21.32
            -0.00,21.78 0.19,22.19 0.48,22.48
            0.49,22.49 0.49,22.49 0.50,22.50
            0.50,22.50 0.70,22.60 0.70,22.60
            0.87,22.77 1.08,22.88 1.32,22.93
            1.58,22.97 1.87,22.97 2.20,22.90
            2.20,22.90 6.60,21.40 6.60,21.40
            6.60,21.40 10.90,22.90 10.90,22.90
            10.90,22.90 11.50,23.00 11.50,23.00
            11.50,23.00 59.10,23.00 59.10,23.00
            59.25,23.00 59.41,22.96 59.59,22.89
            59.66,22.87 59.73,22.83 59.80,22.80
            59.80,22.80 65.90,19.70 65.90,19.70
            65.90,19.70 75.30,19.70 75.30,19.70
            75.36,19.70 75.41,19.70 75.47,19.70
            78.10,19.70 80.69,19.53 83.22,19.19
            85.19,18.92 87.12,18.53 89.00,18.01
            92.00,17.14 94.60,16.00 96.80,14.60
            97.15,14.41 97.50,14.21 97.84,13.99
            98.52,13.55 99.07,13.13 99.48,12.72
            99.80,12.43 100.00,12.00 100.00,11.53
            100.00,11.53 100.00,11.53 100.00,11.53
            100.00,11.52 100.00,11.52 100.00,11.52
            100.00,11.51 100.00,11.51 100.00,11.51
            100.00,11.50 100.00,11.49 100.00,11.48
            100.00,11.03 99.81,10.62 99.52,10.32
            99.51,10.32 99.51,10.31 99.50,10.31
            99.27,10.08 99.03,9.87 98.78,9.68
            98.25,9.27 97.59,8.85 96.80,8.41
            95.52,7.59 94.15,6.89 92.73,6.30
            91.54,5.80 90.30,5.37 89.03,5.01
            86.47,4.31 83.76,3.82 80.99,3.57
            79.19,3.40 77.32,3.31 75.43,3.31
            75.39,3.31 75.34,3.31 75.30,3.31
            75.30,3.31 75.30,3.31 75.30,3.31 Z
        M 73.70,16.40
        C 73.70,16.40 67.30,16.40 67.30,16.40
            67.30,16.40 67.30,14.80 67.30,14.80
            67.30,14.80 67.30,14.79 67.30,14.78
            67.30,14.33 67.11,13.92 66.82,13.62
            66.81,13.61 66.81,13.61 66.80,13.60
            66.50,13.30 66.09,13.11 65.62,13.11
            65.62,13.11 65.62,13.11 65.62,13.11
            65.62,13.11 65.61,13.10 65.60,13.10
            65.59,13.10 65.59,13.10 65.58,13.10
            65.38,13.10 65.18,13.14 65.00,13.21
            64.79,13.30 64.60,13.44 64.45,13.60
            64.15,13.94 64.00,14.34 64.00,14.80
            64.00,14.80 64.00,17.00 64.00,17.00
            64.00,17.00 58.70,19.70 58.70,19.70
            58.70,19.70 11.70,19.70 11.70,19.70
            11.70,19.70 8.20,18.50 8.20,18.50
            8.20,18.50 8.20,14.70 8.20,14.70
            8.20,14.24 8.05,13.86 7.75,13.56
            7.50,13.30 7.15,13.14 6.77,13.11
            6.72,13.11 6.66,13.11 6.60,13.11
            6.60,13.11 6.60,13.11 6.60,13.11
            6.13,13.11 5.73,13.26 5.40,13.56
            5.10,13.82 4.91,14.19 4.90,14.62
            4.90,14.65 4.90,14.67 4.90,14.70
            4.90,14.70 4.90,14.70 4.90,14.70
            4.90,14.70 4.90,18.50 4.90,18.50
            4.90,18.50 3.30,19.00 3.30,19.00
            3.30,19.00 3.30,13.11 3.30,13.11
            3.77,13.11 4.15,12.96 4.45,12.66
            4.70,12.40 4.87,12.06 4.89,11.68
            4.90,11.62 4.90,11.57 4.90,11.51
            4.90,11.51 4.90,11.51 4.90,11.51
            4.90,11.04 4.75,10.64 4.45,10.31
            4.19,10.01 3.81,9.82 3.39,9.81
            3.37,9.81 3.34,9.81 3.32,9.81
            3.31,9.81 3.31,9.81 3.30,9.81
            3.30,9.81 3.30,3.91 3.30,3.91
            3.30,3.91 4.90,4.51 4.90,4.51
            4.90,4.51 4.90,8.11 4.90,8.11
            4.90,8.12 4.90,8.12 4.90,8.13
            4.90,8.58 5.09,9.00 5.38,9.29
            5.39,9.30 5.39,9.30 5.40,9.31
            5.70,9.62 6.11,9.81 6.58,9.81
            6.58,9.81 6.58,9.81 6.58,9.81
            6.58,9.81 6.59,9.81 6.60,9.81
            6.61,9.81 6.61,9.81 6.62,9.81
            6.82,9.81 7.02,9.77 7.20,9.70
            7.41,9.61 7.60,9.48 7.75,9.31
            8.05,8.97 8.20,8.57 8.20,8.11
            8.20,8.11 8.20,4.51 8.20,4.51
            8.20,4.51 11.70,3.31 11.70,3.31
            11.70,3.31 58.70,3.31 58.70,3.31
            58.70,3.31 64.00,6.01 64.00,6.01
            64.00,6.01 64.00,8.21 64.00,8.21
            64.00,8.67 64.15,9.06 64.45,9.36
            64.70,9.61 65.05,9.78 65.43,9.80
            65.48,9.80 65.54,9.81 65.60,9.81
            66.07,9.81 66.47,9.66 66.80,9.36
            67.10,9.10 67.29,8.72 67.30,8.29
            67.30,8.27 67.30,8.24 67.30,8.21
            67.30,8.21 67.30,6.61 67.30,6.61
            67.30,6.61 73.70,6.61 73.70,6.61
            73.70,6.61 73.70,16.40 73.70,16.40 Z
        M 55.90,9.81
        C 55.90,9.81 15.10,9.81 15.10,9.81
            15.09,9.81 15.09,9.81 15.08,9.81
            14.62,9.81 14.21,9.62 13.92,9.32
            13.91,9.32 13.91,9.31 13.90,9.31
            13.76,9.17 13.64,9.01 13.55,8.83
            13.46,8.63 13.40,8.41 13.40,8.17
            13.40,8.16 13.40,8.16 13.40,8.16
            13.40,8.16 13.40,8.16 13.40,8.16
            13.40,7.75 13.56,7.38 13.81,7.10
            13.84,7.07 13.87,7.04 13.90,7.01
            14.20,6.70 14.61,6.51 15.08,6.51
            15.08,6.51 15.08,6.51 15.08,6.51
            15.08,6.51 15.09,6.51 15.10,6.51
            15.10,6.51 55.90,6.51 55.90,6.51
            55.91,6.51 55.91,6.51 55.92,6.51
            56.13,6.51 56.32,6.55 56.50,6.62
            56.71,6.70 56.90,6.84 57.05,7.01
            57.35,7.34 57.50,7.74 57.50,8.21
            57.50,8.67 57.35,9.06 57.05,9.36
            56.80,9.61 56.45,9.78 56.07,9.80
            56.02,9.80 55.96,9.81 55.90,9.81
            55.90,9.81 55.90,9.81 55.90,9.81 Z
        M 83.50,7.21
        C 83.50,7.21 83.50,15.80 83.50,15.80
            82.68,15.93 81.80,16.03 80.92,16.11
            79.99,16.20 79.03,16.28 78.02,16.34
            77.74,16.36 77.40,16.39 77.05,16.40
            77.05,16.40 77.00,6.61 77.00,6.61
            78.03,6.66 79.05,6.73 80.07,6.82
            81.00,6.90 81.87,7.00 82.69,7.10
            82.96,7.13 83.23,7.16 83.50,7.20
            83.50,7.20 83.50,7.21 83.50,7.21 Z
        M 86.80,15.10
        C 86.80,15.10 86.80,7.91 86.80,7.91
            87.56,8.06 88.31,8.25 89.05,8.47
            90.22,8.81 91.25,9.19 92.15,9.61
            92.66,9.83 93.16,10.08 93.66,10.33
            94.44,10.74 95.12,11.13 95.70,11.51
            95.70,11.51 95.10,11.91 95.10,11.91
            94.77,12.10 94.41,12.29 94.06,12.48
            93.30,12.87 92.65,13.18 92.10,13.40
            92.10,13.40 92.00,13.41 92.00,13.41
            91.33,13.69 90.62,13.96 89.90,14.21
            88.75,14.60 87.72,14.90 86.80,15.10 Z`
})
